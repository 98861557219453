class ZIDXLandingPageDocumentation implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxLandingPageDocumentationContainer";
    }
    getMatchURL(){
        return "/landing-page-documentation/";
    }
    render(){
        console.log("landing page documentation");
        ZIDX.runLoadFunctions();
        // console.log(window.ZIDXOptions.loadOnceFunctions());
    }
}